<template>
<div>
  <loading-overlay :active="isLoadingGeneral" :is-full-page="true" loader="bars" />
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader>
          {{ `${this.$t('label.change')} ${this.$t('label.password')}: ${getTitulo}`}}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12" class="mb-2">
              <small> {{ $t('label.userData') }}</small>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6"> 
              <CInput
                disabled
                v-model.trim="user.UserCi"
                v-uppercase
                size="sm"
                :label="$t('label.ID')"
                horizontal
                addLabelClasses="text-right"
                maxlength="10"
              />
              <CInput
                disabled
                v-model.trim="user.UserName"
                v-uppercase
                horizontal
                size="sm"
                :label="`${$t('label.name')}(S)`"
                addLabelClasses="text-right"
                maxlength="50"
              />
              <CInput
                disabled
                v-model.trim="user.UserLastName"
                v-uppercase
                horizontal
                size="sm"
                :label="`${$t('label.lastName')}(S)`"
                addLabelClasses="text-right"
                maxlength="50"
              />
              <CInput
                disabled
                v-model.trim="user.Login"
                v-uppercase
                horizontal
                size="sm"
                label="LOGIN"
                addLabelClasses="text-right"
                maxlength="50"
              />
              <div class="form-group text-center">
                <CLink class="table-link" @click.stop="toggleTable">
                  <CIcon
                    name="cil-chevron-right"
                    :class="{
                      'ml-4': true,
                      'arrow-icon': true,
                      'rotate': tableShow
                    }"
                  />
                  <span class="ml-1">{{ tableRolesText }}</span>
                </CLink>
              </div>
            </CCol>
            <CCol sm="6">
              <CSelect
                disabled
                :value.sync="user.TpUserId"
                size="sm"
                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                :label="$t('label.userType')"
                placeholder="Seleccione Tipo de Usuario"
                :options="typesOptions"
                addLabelClasses="text-right"
              />
              <CInput
                disabled
                v-model.trim="user.PrimaryPhone"
                size="sm"
                type="tel"
                :label="$t('label.mainPhone')"
                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                addLabelClasses="text-right"
                maxlength="20"
              />
              <CInput
                disabled
                v-model.trim="user.SecondaryPhone"
                size="sm"
                type="tel"
                :label="$t('label.secondaryPhone')"
                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                addLabelClasses="text-right"
                maxlength="20"
              />
              <CInput
                disabled
                v-model.trim="user.PrimaryEmail"
                size="sm"
                type="email"
                :label="$t('label.mainEmail')"
                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                addLabelClasses="text-right"
                maxlength="100"
              />
              <CInput
                disabled
                v-model.trim="user.SecondaryEmail"
                size="sm"
                type="email"
                :label="$t('label.secondaryEmail')"
                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                addLabelClasses="text-right"
                maxlength="100"
              />
            </CCol>
          </CRow>
          <CCollapse :show="tableShow">
            <CRow>
              <CCol sm="12">
                <CCard body-wrapper>
                  <dataTableExtended
                  class="align-center-row-datatable"
                  :items="rolesList"
                  :fields="fields"
                  column-filter
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :noItemsView="tableText.noItemsViewText"
                  :items-per-page="tableText.itemsPerPage"
                  hover
                  small
                  sorter   
                  pagination
                  >
                    <template #RowNumber="{ item }">
                      <td>
                        {{ item.RowNumber }}
                      </td>
                    </template>
                    <template #StatusText="{item}">
                      <td class="text-center">
                        <CBadge :color="getBadge(item.StatusText)">
                          {{item.StatusText}}
                        </CBadge>
                      </td>
                    </template>
                  </dataTableExtended>
                </CCard>
              </CCol>
            </CRow>
          </CCollapse>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol sm="12">
      <CCard>
        <CCardBody>
          <CRow alignHorizontal="center">
            <CCol sm="5">
              <div class="form-group form-row" role="group">
                <label for="password" class="required text-right col-form-label col-sm-4 col-form-label-sm">
                  {{ $t('label.password') }}
                </label>
                <div class="col-sm-8 input-group-sm">
                  <input
                    v-model="$v.change.password.$model"
                    id="password"
                    :type="inputType"
                    class="form-control"
                    :class="inputClasses($v.change.password)"
                    placeholder="Password"
                  >
                  <label
                    for="password"
                    class="btn input-group-text input-icon-append"
                    @click.prevent="togglePassword"
                  >
                    <CIcon v-if="!showPassword" name="cil-eye"/>
                    <CIcon v-if="showPassword" name="cil-eye-slash"/>
                  </label>
                  <div class="invalid-feedback">
                    Invalid length
                  </div>
                </div>
              </div>
            </CCol>
            <CCol sm="6">
              <div class="form-group form-row" role="group">
                <label for="confirmPassword" class="required text-right col-form-label col-sm-5 col-form-label-sm">
                  {{ `${$t('label.Confirm')} ${ $t('label.password')}` }}
                </label>
                <div class="col-sm-7 input-group-sm">
                  <input
                    v-model="$v.change.confirmPassword.$model"
                    id="confirmPassword"
                    :type="inputType"
                    class="form-control"
                    :class="inputClasses($v.change.confirmPassword)"
                    placeholder="Password"
                  >
                  <label
                    for="password"
                    class="btn input-group-text input-icon-append"
                    @click.prevent="togglePassword"
                  >
                    <CIcon v-if="!showPassword" name="cil-eye"/>
                    <CIcon v-if="showPassword" name="cil-eye-slash"/>
                  </label>
                  <div class="invalid-feedback">
                    Password do not match
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow>
            <CCol sm="12" class="d-flex justify-content-end align-items-center">
              <CButton
                square
                color="add"
                :disabled="isSubmitValid"
                class="d-flex align-items-center mr-2"
                @click="submit"
              >
                <div v-if="!isSubmit">
                  <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
                </div>
                <div v-if="isSubmit">
                  <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span class="ml-1">{{$t('button.accept')}}</span>
                </div>
              </CButton>
              <CButton
                square
                color="wipe"
                :disabled="isSubmit"
                class="d-flex align-items-center"
                to="/"
              >
                <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
              </CButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</div>
</template>

<script>
import ChangePasswordValidations from '@/_validations/auth/ChangePasswordValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import AuthService from '@/_services/auth-service';
import { tableTextHelpers } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import AuthMixin from '@/_mixins/auth';
import { mapState } from 'vuex';

//Data
function data() {
  return {
    isLoadingGeneral: false,
    isSubmit: false,
    tableShow: false,
    titulo: '',
    userTypes: [],
    user: {
      TpUserId: '',
      Login: '',
      UserCi: '',
      UserName: '',
      UserLastName: '',
      PrimaryPhone: '',
      SecondaryPhone: '',
      PrimaryEmail: '',
      SecondaryEmail: '',
    },
    change: {
      password: '',
      confirmPassword: ''
    },
    roles: [],
    activePage: 1
  }
}

//Created
function created() {
  this.loadData();
}

//Methods
function loadData() {
this.isLoadingGeneral = true;
  let peticiones = [
    this.$http.ejecutar('GET', 'UserTp-list', { Filter: 'ACTIVO' }),
    this.$http.ejecutar('GET', 'User-by-id', { UserId: this.userData.UserId })
  ];

  Promise.all(peticiones)
  .then((responses) => {
    this.userTypes = responses[0].data.data;
    this.roles = responses[1].data.data[0].UserRoleJson;
    this.setUserData(responses[1].data.data[0]);
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingGeneral = false;
  });
}
function submit() {
  this.isSubmit = true;
  let userData = { password: this.change.password };

  AuthService.changePasswordUser(userData)
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡Exito!',
      text: response.msg,
      type: "success"
    });
    this.$router.push('/');
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.isSubmit = false;
  });
}
function setUserData(newUser) {
  this.user.TpUserId = newUser.TpUserId;
  this.user.Login = newUser.Login;
  this.user.UserCi = newUser.UserCi;
  this.user.UserName = newUser.UserName;
  this.user.UserLastName = newUser.UserLastName;
  this.user.PrimaryPhone = newUser.PrimaryPhone;
  this.user.SecondaryPhone = newUser.SecondaryPhone;
  this.user.PrimaryEmail = newUser.PrimaryEmail;
  this.user.SecondaryEmail = newUser.SecondaryEmail;
  this.user.Status = newUser.FgActUser? 1:0;

  this.change.password = newUser.Password;
  this.change.confirmPassword = newUser.Password;
  this.titulo = `${newUser.UserName} ${newUser.UserLastName}`;
  this.$v.$touch();
}
function toggleTable(event){
  this.tableShow = !this.tableShow;
}

//Computed
function getTitulo() {
  return this.titulo;
}
function rolesList() {
  let index = 0;
  return this.roles.map((rol) => Object.assign({}, rol, {
    RowNumber: ++index,
    RoleNameEs: rol.RoleNameEs ?? 'N/A',
    RoleNameEn: rol.RoleNameEn ?? 'N/A',
    Sucursal: rol.BranchName?rol.BranchName:'N/A',
    Patio: rol.YardName?rol.YardName:'N/A',
    StatusText: rol.Status
  }));
}
function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}
function isTypesEmpty(){
  return this.userTypes.length == 0;
}
function typesOptions() {
  return this.userTypes.map((type) => Object.assign({}, type, {
    value: type.TpUserId,
    label: type.TpUserName
  }));
}
function tableRolesText() {
  return this.tableShow
    ?  `${this.$t('label.hideTableRolesPass')}`
    : `${this.$t('label.viewTableRolespass')}`
}

function fields() {
  return [
    { key: 'RowNumber', label: '#',_style: 'width:1%;' ,filter: false},
    { key: 'RoleNameEs', label: `${this.$t('label.role')} (ES)`, _style: 'width:18%;', _classes:'text-center' },
    { key: 'RoleNameEn', label: `${this.$t('label.role')} (EN)`, _style: 'width:18%;', _classes:'text-center'  },
    { key: 'BranchName', label: this.$t('label.branch'), _style: 'width:15%;', _classes:'text-center'  },
    { key: 'Patio', label: this.$t('label.yard'), _style: 'width:15%;', _classes:'text-center'  },
    { key: 'StatusText', label: this.$t('label.status'), _style:'width:10%; text-align:center;' }
  ]
}

export default {
  name: 'change-password',
  mixins: [GeneralMixin, AuthMixin],
  data,
  validations: ChangePasswordValidations,
  directives: UpperCase,
  created,
  methods: {
    loadData,
    submit,
    setUserData,
    toggleTable
  },
  computed: {
    getTitulo,
    rolesList,
    isSubmitValid,
    isTypesEmpty,
    typesOptions,
    tableRolesText,
    fields,
    ...mapState({
        userData: state => state.auth.user,
    })
  },
  watch: {}
}
</script>

<style scoped>
a.table-link{
  color: #6c868d;
  text-decoration: none;
  transition: color .4s ease-in-out;
}
a:hover.table-link{
  color: #17b6df;
}
.arrow-icon {
  transition: transform 0.15s ease-in;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.arrow-icon.rotate {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.input-icon {
  padding-left: 2.3rem;
  border-radius: 0.25rem !important;
}
.input-icon-prep {
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  border-radius: 0.25rem !important;
}
.input-icon-prepend {
  position: absolute;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  background-color: transparent;
  border: transparent;
  border-radius: 0.25rem;
  z-index: 5;
}
.input-icon-append {
  position: absolute;
  right: 0;
  top: 0;
  height: calc(1.2em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  background-color: transparent;
  border: transparent;
  z-index: 5;
  box-shadow: none;
}
.input-icon-append:hover {
  color: #000;
}

.es-valido {
  border-color: #2eb85c;
}
.es-invalido {
  border-color: #e55353;
}
.es-invalido ~ .invalid-feedback {
  display: block;
}
.form-control.es-valido:focus {
  border-color: #2eb85c;
  box-shadow: 0 0 0 .2rem rgba(46,184,92,.25);
}
.form-control.es-invalido:focus {
  border-color: #e55353;
  box-shadow: 0 0 0 .2rem rgba(229,83,83,.25);
}
</style>