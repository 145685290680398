import {
  required,
  minLength,
  maxLength,
  sameAs
} from 'vuelidate/lib/validators';

import { alphaNumSpecials } from '../funciones';

export default () => {
  return {
    change: {
      password: { required, alphaNumSpecials, minLength: minLength(6), maxLength: maxLength(20) },
      confirmPassword: { sameAsPassword: sameAs('password') }
    }
  }
}